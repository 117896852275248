<template>
    <el-container class="content">
      <el-header class="header">
        <span class="left el-icon-arrow-left" @click="returnOn"></span>
        {{titleType}}
      </el-header>
      <el-main class="main">
        <div class="info">
          <div class="title">
            基本信息
          </div>
          <el-form label-position="left" label-width="100px" :model="forms">
            <el-form-item label="商品名称">
              <el-input v-model="forms.name" placeholder="输入商品名称"></el-input>
            </el-form-item>
            <el-form-item label="商品简介">
              <el-input v-model="forms.introduce" placeholder="输入商品简介"></el-input>
            </el-form-item>
            <!-- <el-form-item label="货号">
              <el-input v-model="form.articleNum" placeholder="输入商品货号"></el-input>
            </el-form-item> -->
            <el-form-item label="商品分类">
              <div @click="goodsClassClick">
                <el-input v-model="form.goodsClass" readonly suffix-icon="el-icon-arrow-right" placeholder="选择商品分类">
                </el-input>
              </div>
            </el-form-item>
            <el-form-item label="商品品牌">
              <div @click="goodsBrandClick">
                <el-input v-model="form.goodsBrand" readonly suffix-icon="el-icon-arrow-right" placeholder="选择商品品牌">
                </el-input>
              </div>
  
            </el-form-item>
            <el-form-item label="规格型号">
              <div @click="goodsModelClick">
                <el-input v-model="form.goodsModel" readonly suffix-icon="el-icon-arrow-right" :placeholder="placeholder">
                </el-input>
              </div>
            </el-form-item>
            <el-form-item label="单价">
              <el-input v-model="forms.original_price" placeholder="最多保留小数点后两位"></el-input>
            </el-form-item>
            <el-form-item label="会员价">
              <el-input v-model="forms.vip_price" placeholder="最多保留小数点后两位"></el-input>
            </el-form-item>
            <el-form-item label="基本单位">
              <div @click="basicUnitClick">
                <el-input v-model="form.basicUnit" readonly suffix-icon="el-icon-arrow-right" placeholder="选择基本单位">
                </el-input>
              </div>
            </el-form-item>
            <el-form-item label="热门产品">
              <el-switch v-model="forms.stat" :active-value="1" :inactive-value="0" active-color="#409eff" inactive-color="#cccccc" >
              </el-switch>
            </el-form-item>
            <el-form-item label="初始库存数">
              <el-input v-model="forms.stock" placeholder="输入初始库存数"></el-input>
            </el-form-item>
            <el-form-item label="最低预警库存">
              <el-input v-model="forms.warn" placeholder="输入最低预警库存"></el-input>
            </el-form-item>
            <el-form-item label="商品图片">
              <el-upload class="avatar-uploader" :action="$imgFile"
                  :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                  <span>点击上传</span>
              </el-upload>
            </el-form-item>
          </el-form>
        </div>
        <div class="info add-set" v-show="forms.image">
          <div class="title">
            商品图片
          </div>
          <el-main>
            <img :src="$getimgsrc(forms.image)" alt="商品图片">
          </el-main>
        </div>
        <div class="info add-set">
          <div class="title">
            更多设置
          </div>
          <div class="mark-ipt">
            <el-form label-position="left" label-width="100px" :model="form">
              <el-form-item label="允许积分兑换">
                <el-switch v-model="forms.con" :active-value="1" :inactive-value="0"	 active-color="#409eff" inactive-color="#cccccc">
                </el-switch>
              </el-form-item>
              <el-form-item label="是否赠送积分">
                <el-switch v-model="forms.zscon" :active-value="1" :inactive-value="0"	 active-color="#409eff" inactive-color="#cccccc">
                </el-switch>
              </el-form-item>
              <el-form-item label="赠送积分">
                <el-input v-model="forms.zsinteg" placeholder="输入赠送积分"></el-input>
              </el-form-item>
              <el-form-item label="兑换所需积分">
                <el-input v-model="forms.integ" placeholder="输入积分值"></el-input>
              </el-form-item>
              <el-form-item label="提成设置">
                <el-radio v-model="forms.draw" :label="1">固定</el-radio>
                <el-radio v-model="forms.draw" :label="2">比例</el-radio>
                <el-radio v-model="forms.draw" :label="3">不提成</el-radio>
              </el-form-item>
              <el-form-item label="提成金额">
                <el-input v-model="forms.drawnum" placeholder="提成金额"></el-input>
              </el-form-item>
              <!-- <el-form-item label="换购积分">
                <el-input v-model="form.buyIntegral" placeholder="输入换购积分"></el-input>
              </el-form-item> -->
              <!-- <el-form-item label="是否提成">
                <div :class="form.isCommission===1?'box-btn box-btn1':'box-btn'" @click="form.isCommission=1">
                  固定提成
                </div>
                <div :class="form.isCommission===2?'box-btn box-btn1':'box-btn'" @click="form.isCommission=2">
                  比例提成
                </div>
                <div :class="form.isCommission===3?'box-btn box-btn1':'box-btn'" @click="form.isCommission=3">
                  不提成
                </div>
              </el-form-item> -->
            </el-form>
          </div>
        </div>
        <div style="width:100%;height:100px;"></div>
        <div class="footer">
          <el-button type="primary" round @click="addshop">保存</el-button>
        </div>
      </el-main>
      <!-- 商品分类 dialog -->
      <el-dialog class="goodsBrandDialog dialog" center :visible.sync="goodsClassShow" width="40%"
        :before-close="handleClose" :show-close="false">
        <template slot="title">
          <div class="box">
            <div class="dialogTitle">
              <div class="left" @click="jumpManageAttr('分类')">分类管理</div>
              <div class="center">选择商品分类</div>
              <div class="right el-icon-close" @click="handleClose">
              </div>
            </div>
            <div class="dialogSearch">
              <el-input clearable placeholder="输入商品分类名称搜索" prefix-icon="el-icon-search" v-model="goodsClassSearchVal"
                @keyup.enter.native="classSearchChange">
              </el-input>
              <el-button type="primary" @click="classSearchChange">搜索</el-button>
            </div>
          </div>
        </template>
        <el-container class="container">
          <el-main class="cell-list">
            <el-radio-group v-model="goodsClassRadio">
              <el-radio :label="item.id" v-model="forms.shopcate_id" v-for="(item,i) in item" :key="i" @change="changeRadio(item)">
                <div class="dialog-title">
                  {{item.name}}
                </div>
              </el-radio>
            </el-radio-group>
          </el-main>
        </el-container>
        <div class="btn">
          <!-- <el-button type="primary" round @click="confirm">确定</el-button> -->
        </div>
      </el-dialog>
      <!-- 商品品牌 dialog -->
      <el-dialog class="goodsBrandDialog dialog" center :visible.sync="goodsBrandShow" width="40%"
        :before-close="handleClose" :show-close="false">
        <template slot="title">
          <div class="box">
            <div class="dialogTitle">
              <div class="left" @click="jumpManageAttr('品牌')">品牌管理</div>
              <div class="center">选择商品品牌</div>
              <div class="right el-icon-close" @click="handleClose">
              </div>
            </div>
            <div class="dialogSearch">
              <el-input clearable placeholder="输入品牌名称搜索" prefix-icon="el-icon-search" v-model="goodsBrandSearchVal"
                @keyup.enter.native="brandSearchChange">
              </el-input>
              <el-button type="primary" @click="brandSearchChange">搜索</el-button>
            </div>
          </div>
        </template>
        <el-container class="container">
          <el-main class="cell-list">
            <el-radio-group v-model="goodsBrandRadio">
              <el-radio :label="item.id" v-for="(item,i) in item" :key="i" @change="changeRadio(item)">
                <div class="dialog-title">
                  {{item.name}}
                </div>
              </el-radio>
            </el-radio-group>
          </el-main>
        </el-container>
      </el-dialog>
      <!-- 规格型号 dialog -->
      <el-dialog class="goodsClassDialog goodsModelDialog dialog " center :visible.sync="goodsModelShow" width="40%"
        :before-close="handleClose" :show-close="false">
        <template slot="title">
          <div class="box">
            <div class="dialogTitle">
              <div class="left" @click="jumpManageAttr('规格')">规格管理</div>
              <div class="center">选择规格型号</div>
              <div class="right el-icon-close" @click="handleClose">
              </div>
            </div>
            <div class="dialogSearch">
              <el-input clearable placeholder="输入规格名称或选项值搜索" prefix-icon="el-icon-search" v-model="goodsModelSearchVal"
                @keyup.enter.native="modelSearchChange">
              </el-input>
              <el-button type="primary" @click="modelSearchChange">搜索</el-button>
            </div>
          </div>
        </template>
        <el-container class="container">
          <el-main class="cell-list">
            <el-radio-group v-model="goodsModelRadio1">
              <el-radio :label="item.id" v-for="(item,index) in item" :key="index" @change="changeRadio(item,item.id)">
                      <div class="dialog-title">
                        {{item.name}}
                      </div>
              </el-radio>
            </el-radio-group>
            <!-- <el-collapse v-model="goodsModelRadio" accordion>
  
              <el-checkbox-group v-model="goodsModelRadio1">
              <el-collapse-item :title="item.name" :name="item.name" v-for="(item,index) in item" :key="index">
                <el-checkbox :label="item2.id" v-for="(item2,i) in item.shuxing" :key="i" @change="changeRadio(item2,item.id)">
                      <div class="dialog-title">
                        {{item2.name}}
                      </div>
                  </el-checkbox>
              </el-collapse-item>
            </el-checkbox-group>
  
            </el-collapse> -->
          </el-main>
        </el-container>
        <div class="btn">
          <el-button type="primary" round @click="confirm">确定</el-button>
        </div>
      </el-dialog>
      <!-- 基本单位 dialog -->
      <el-dialog class="goodsBrandDialog dialog" center :visible.sync="basicUnitShow" width="40%"
        :before-close="handleClose" :show-close="false">
        <template slot="title">
          <div class="box">
            <div class="dialogTitle">
              <div class="left" @click="jumpManageAttr('单位')">单位管理</div>
              <div class="center">选择基本单位</div>
              <div class="right el-icon-close" @click="handleClose">
              </div>
            </div>
            <div class="dialogSearch">
              <el-input clearable placeholder="输入单位名称搜索" prefix-icon="el-icon-search" v-model="basicUnitSearchVal"
                @keyup.enter.native="unitSearchChange">
              </el-input>
              <el-button type="primary" @click="unitSearchChange">搜索</el-button>
            </div>
          </div>
        </template>
        <el-container class="container">
          <el-main class="cell-list">
            <el-radio-group v-model="basicUnitRadio">
              <el-radio :label="item.id" v-for="(item,i) in item" :key="i" @change="changeRadio(item)">
                <div class="dialog-title">
                  {{item.name}}
                </div>
              </el-radio>
            </el-radio-group>
          </el-main>
        </el-container>
      </el-dialog>
  
    </el-container>
  </template>
  
  <script>
    export default {
      data() {
        return {
          value:false,
          item: [],
          suxinList:[],//规格属性
          titleType: '',
          form: {
            goodsName: '',
            goodsNum: '',
            articleNum: '',
            goodsClass: '',
            goodsBrand: '',
            goodsModel: '',
            unitPrice: '',
            vipPrice: '',
            basicUnit: '',
            hotGoods: true,
            initialNumber: '',
            goodsWarning: '',
            // 更多设置
            isIntegral: true,
            integralNum: '',
            isBuy: true,
            buyIntegral: '',
            isCommission: 1,
            buyPrice: '',
          },
          placeholder:'请选择规格型号',
          goodsClassShow: false,
          goodsClassRadio: [],
          goodsClassRadio1: [],
          goodsClassSearchVal: '',
          goodsBrandShow: false,
          goodsBrandRadio: [],
          goodsBrandSearchVal: '',
          goodsModelShow: false,
          goodsModelRadio: [],
          goodsModelRadio1: [],
          goodsModelRadio2: [],
          goodsModelSearchVal: '',
          basicUnitShow: false,
          basicUnitRadio: [],
          basicUnitSearchVal: '',
          //规格父id
          guigefuId:[],
          //规格结算id
          guigeOverList:'',
          forms:{
            shopcate_id:'',//分类id
            shopmake_id:'',//品牌id
            shopunit_id:'',//单位id
            shopspecs_id:'',//规格id
            name:'',//商品名称
            image:'',//图片
            introduce:'',//简介
            original_price:'',//售价
            vip_price:'',//会员价
            stock:'',//库存
            content:'',//商品详情
            con:1,//1允许积分兑换0不允许积分兑换
            integ:'',//积分兑换需要的积分
            zscon:0,//1赠送积分0不赠送积分
            zsinteg:'',//赠送积分
            draw:1,//1=固定,2=比例,3=不提成
            drawnum:'',//提成
            warn:'',//最低库存预警
            stat:0,//1热门商品0不是热门
          },
          shopid:null,
        }
      },
      mounted() {
        this.titleType = this.$route.query.type === 'edit' ? '编辑商品' : '添加商品'
        this.shopid = this.$route.query.id
        this.forms = JSON.parse(this.$route.query.info)
        this.form.goodsClass = this.forms.shopcate_id
        if(this.forms.stat == "是"){
            this.forms.stat = 1
        }else{
            this.forms.stat = 0
        }
        if(this.forms.con == "是"){
            this.forms.con = 1
        }else{
            this.forms.con = 0
        }
        if(this.forms.zscon == "是"){
            this.forms.zscon = 1
        }else{
            this.forms.zscon = 0
        }
        if(this.forms.draw == "固定"){
            this.forms.draw = 1
        }else if(this.forms.draw == "比例"){
            this.forms.draw = 2
        }else if(this.forms.draw == "不提成"){
            this.forms.draw = 3
        }
        //分类
        this.$http.post('api/store/shop/shopcate').then(res =>{
            for(let i in res.data.data){
                if(this.forms.shopcate_id == res.data.data[i].name ){
                    this.goodsClassRadio = res.data.data[i].id
                    this.forms.shopcate_id = res.data.data[i].id

                }
            }
        })
        this.form.goodsBrand = this.forms.shopmake_id
        //品牌
        this.$http.post('api/store/shop/shopmake').then(res =>{
            for(let i in res.data.data){
                if(this.forms.shopmake_id == res.data.data[i].name ){
                    this.goodsBrandRadio = res.data.data[i].id
                    this.forms.shopmake_id = res.data.data[i].id
                }
                
            }
        })
        this.form.basicUnit = this.forms.shopunit_id
        //单位
        this.$http.post('api/store/shop/shopunit').then(res =>{
            for(let i in res.data.data){
                if(this.forms.shopunit_id == res.data.data[i].name ){
                    this.basicUnitRadio = res.data.data[i].id
                    this.forms.shopunit_id = res.data.data[i].id
                }
                
            }
        })
        this.getGuigeList()
        
      },
      methods: {
        //修改商品
        addshop(){
          this.$http.post('api/store/shop/editshop',this.forms).then(res =>{
            this.$message.success(res.data.msg)
          })
        },
         // 修改头像
         handleAvatarSuccess(res, file) {
          // this.imageUrl = URL.createObjectURL(file.raw);
          this.forms.image = res.data.url
        },
        beforeAvatarUpload(file) {
        //   console.log(file.type);
          const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
          const isLt2M = file.size / 1024 / 1024 < 2;
          if (!isJPG) {
            this.$message.error('上传图片只能是 JPG/PNG 格式!');
          }
          if (!isLt2M) {
            this.$message.error('上传图片大小不能超过 2MB!');
          }
          return isJPG && isLt2M;
        },
        //设置热门
        remen(e){
          if(e){
            this.forms.stat = 1
          }else{
            this.forms.stat = 0
          }
        },
        //获取分类列表
        getClassList(){
          this.$http.post('api/store/shop/shopcate').then(res =>{
            this.item = res.data.data
          })
        },
        //获取规格列表
        getGuigeList(){
          this.$http.post('api/store/shop/guigeshuxing').then(res =>{
            this.item = res.data.data
             //规格id
            this.$http.post('api/store/shop/shopguige',{
                id:this.shopid
            }).then(req =>{
                for(let i in this.item){
                  if(this.item[i].id == req.data.data.z[0]){
                    this.forms.shopspecs_id = Number(req.data.data.z[0])
                    this.goodsModelRadio1 = Number(req.data.data.z[0])
                    this.form.goodsModel = this.item[i].name
                  }
                }
                // console.log(res);
                // this.guigefuId = res.data.data.z
                // this.goodsModelRadio1 = res.data.data.zi
                // if(this.goodsModelRadio1.length != 0){
                //     this.placeholder = '已选择规格'
                //     this.forms.shopspecs_id = this.goodsModelRadio1.concat(this.guigefuId).join()
                // }
            })
          })
        },
        //获取规格属性列表
        getSuxingList(){
          this.$http.post('api/store/shop/shopspecspid').then(res =>{
            this.suxinList = res.data.data
          })
        },
        //获取品牌
        getPinpaiList(){
          this.$http.post('api/store/shop/shopmake').then(res =>{
            this.item = res.data.data
          })
        },
         //获取品牌
         getDanweiList(){
          this.$http.post('api/store/shop/shopunit').then(res =>{
            this.item = res.data.data
          })
        },
        // 返回上级
        returnOn() {
          this.$router.go(-1)
        },
        // 显示弹窗
        goodsClassClick() {
          this.getClassList()
          this.goodsClassShow = true
        },
        goodsBrandClick() {
          this.getPinpaiList()
          this.goodsBrandShow = true
        },
        goodsModelClick() {
          // this.getGuigeList()
          this.goodsModelShow = true
        },
        basicUnitClick() {
          this.getDanweiList()
          this.basicUnitShow = true
        },
        // 分类搜索
        classSearchChange() {
          if (this.goodsClassSearchVal.trim()) {
            console.log(this.goodsClassSearchVal.trim());
          } else {
            this.$message.error('搜索内容不能为空')
          }
        },
        // 品牌分类搜索
        brandSearchChange() {
          if (this.goodsBrandSearchVal.trim()) {
            console.log(this.goodsBrandSearchVal.trim());
          } else {
            this.$message.error('搜索内容不能为空')
          }
        },
        // 规格搜索
        modelSearchChange() {
          if (this.goodsModelSearchVal.trim()) {
            console.log(this.goodsModelSearchVal.trim());
          } else {
            this.$message.error('搜索内容不能为空')
          }
        },
        // 单位搜索
        unitSearchChange() {
          if (this.basicUnitSearchVal.trim()) {
            console.log(this.basicUnitSearchVal.trim());
          } else {
            this.$message.error('搜索内容不能为空')
          }
        },
        // 选择弹窗
        changeRadio(item, typeName) {
          if (this.goodsClassShow) {
            this.goodsClassShow = false
            this.forms.shopcate_id = item.id
            this.form.goodsClass = item.name
            // if (this.goodsClassRadio1.indexOf(item) !== -1) {
            //   this.goodsClassRadio1.splice(this.goodsClassRadio1.indexOf(item), 1)
            // } else {
            //   this.goodsClassRadio1.push(item);
            // }
          } else if (this.goodsBrandShow) {
            this.goodsBrandShow = false
            this.forms.shopmake_id = item.id
            this.form.goodsBrand = item.name
          } else if (this.goodsModelShow) {
            //规格选中
            this.guigefuId = item
            // if (this.guigefuId.indexOf(typeName) !== -1) {
            //   // this.guigefuId.splice(this.guigefuId.indexOf(typeName), 1)
            // } else {
            //   this.guigefuId.push(typeName);
            // }
          } else if (this.basicUnitShow) {
            this.basicUnitShow = false
            this.forms.shopunit_id = item.id
            this.form.basicUnit = item.name
          }
        },
        confirm() {
          if (this.goodsClassShow) {
            this.goodsClassShow = false
            // this.form.goodsClass = this.goodsClassRadio1.join()
          } else if (this.goodsModelShow) {
            this.goodsModelShow = false
            this.goodsModelShow = false
            this.form.goodsModel = this.guigefuId.name
            this.forms.shopspecs_id = this.guigefuId.id
            // if(this.goodsModelRadio1.length != 0){
            //   this.placeholder = '已选择规格'
            //   this.goodsModelRadio1 = this.goodsModelRadio1.concat(this.guigefuId)
            //   this.guigeOverList = this.goodsModelRadio1.join()
            //   this.forms.shopspecs_id = this.goodsModelRadio1.join()
            // }
            // this.goodsModelRadio2.forEach(e => {
            //   this.form.goodsModel += e + ':' + this.goodsModelRadio1[e]
            // });
          }
        },
  
        // 关闭dialog
        handleClose() {
          this.goodsClassShow = false
          this.goodsBrandShow = false
          this.goodsModelShow = false
          this.basicUnitShow = false
  
        },
        jumpManageAttr(typeName) {
          this.$router.push({
            path: '/manage-attr',
            query: {
              type: typeName
            }
          })
        },
        confirmSubmit() {
          console.log(this.form);
        }
  
      }
    };
  
  </script>
  
  <style lang="less" scoped>
    .content {
      height: calc(100vh);
    }
  
    // 头部
    .el-header {
      position: relative;
      z-index: 1;
      background-color: #fff;
      font-weight: bold;
      font-size: 16px;
      color: #333;
      height: 50px !important;
      line-height: 50px;
      text-align: center;
      border-bottom: 1px solid #e9e9e9;
  
      .left {
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        color: #46a6ff;
        font-size: 24px;
      }
    }
  
    /deep/ .main.el-main {
      background-color: #f9f9f9;
      padding: 15px !important;
      min-height: calc(100vh);
  
      .info {
        background-color: #fff;
  
        .title {
          border-bottom: 1px solid #f2f2f2;
          padding: 12px;
          font-weight: bold;
          font-size: 14px;
          color: #666;
          margin-bottom: 10px;
        }
  
        .el-form {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          padding-bottom: 15px;
  
          .el-form-item {
            width: 48%;
            margin: 0 1%;
            border-bottom: 1px solid #f2f2f2;
  
            &:nth-last-child(1),
            &:nth-last-child(2) {
              border-bottom: none;
            }
  
            .el-form-item__label {
              font-size: 12px;
            }
  
            .el-input {
              .el-input__inner {
                border: none;
                text-align: right;
  
                &::placeholder {
                  font-size: 12px;
                }
              }
            }
  
            .el-form-item__content {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              height: 100%;
            }
  
            .el-checkbox-group {
              padding-right: 15px;
  
            }
  
            .el-date-editor {
              .el-input__inner {
                padding: 0 15px 0 0;
  
              }
  
              .el-input__prefix {
                display: none;
              }
            }
  
          }
        }
      }
  
      .add-set {
        margin-top: 30px;
  
        .mark-ipt {
          padding: 10px;
  
          .box-btn {
            width: 90px;
            line-height: 30px;
            text-align: center;
            border: 1px solid #f2f2f2;
            border-radius: 50px;
            margin: 0 5px;
          }
  
          .box-btn1 {
            border-color: #63b0ff;
            color: #63b0ff;
            background-color: #f6faff;
          }
        }
  
      }
  
    }
  
    .dialog {
      box-sizing: border-box;
  
      /deep/.el-dialog {
        min-width: 360px;
      }
  
      /deep/ .el-dialog__header {
        padding: 0;
  
        .dialogTitle {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #f2f2f2;
          padding: 15px;
          margin-bottom: 10px;
  
          .left {
            font-size: 12px;
            color: #409eff;
          }
  
          .center {
            font-size: 16px;
            font-weight: bold;
          }
  
          .right {
            font-size: 16px;
            font-weight: bold;
            color: #409eff;
          }
        }
  
        .dialogSearch {
          position: relative;
          top: 20px;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          display: flex;
          justify-content: space-around;
          align-items: center;
          box-sizing: border-box;
          padding: 10px 30px 5px;
  
          .el-button--primary {
            height: 40px;
            margin-left: -5px;
            z-index: 1;
            font-size: 12px;
            border-radius: 0 5px 5px 0;
            border: 1px solid #3d96f2;
          }
  
          .el-button--primary:focus,
          .el-button--primary:hover,
          .el-button:active,
          .el-button:focus,
          .el-button:hover {
            color: #fff;
            border-color: #409eff;
            background-color: #409eff;
          }
  
          /deep/ .el-input__inner {
            height: 35px;
            border: 1px solid #dcdfe6;
            border-right: 0;
            border-radius: 5px 0 0 5px;
  
            &:focus,
            &:hover {
              border-color: #dcdfe6;
            }
          }
        }
      }
  
      /deep/.el-dialog__body {
        height: 400px;
        padding: 0 15px;
  
      }
  
      .container {
        height: 350px;
      }
  
      .btn {
        padding: 5px 30px;
  
        .el-button {
          width: 100%;
        }
      }
    }
  
    .goodsClassDialog {
  
      .cell-list {
        width: 100%;
        padding: 0 20px !important;
  
        /deep/ .el-radio-group {
          width: 100%;
          position: relative;
  
          .el-radio {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #f2f2f2;
            padding: 10px 0;
            margin-bottom: 10px;
            margin-right: 0;
  
            .dialog-title {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              font-size: 12px;
            }
  
            .el-radio__input {
              position: absolute;
              right: 10px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
  
      }
    }
  
    .goodsBrandDialog {
  
      .cell-list {
        width: 100%;
        padding: 0 !important;
  
        /deep/ .el-radio-group {
          width: 100%;
          position: relative;
  
          .el-radio {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #f2f2f2;
            padding: 10px 0;
            margin-bottom: 10px;
            margin-right: 0;
  
            .dialog-title {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              font-size: 12px;
            }
  
            .el-radio__input {
              position: absolute;
              right: 10px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
  
      }
    }
  
    .goodsModelDialog {
      .container {
        .el-collapse {
          border-top: none;
        }
  
        .cell-list {
  
          .box {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
  
            .list {
              width: 30%;
              margin: 1.6%;
              text-align: center;
              line-height: 30px;
              border: 1px solid #efefef;
              box-sizing: border-box;
            }
  
            .list1 {
              border-color: #409eff;
              color: #409eff;
            }
          }
        }
      }
    }
  
    .footer {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      text-align: center;
      background-color: #fff;
      padding: 10px 0;
  
      .el-button {
        width: 30%;
        min-width: 240px;
      }
    }
  
  </style>
  